import {Component, Inject, Injector, OnInit, PLATFORM_ID, Renderer2, RendererFactory2, TemplateRef, ViewChild} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import {QuestionnaireService} from '../../../services/questionnaire.service';
import {AuthenticateServiceModule} from '../../../services/authenticate.service';
import Swal from 'sweetalert2';
import {QuestionnaireModel} from '../../../models/questionnaire.model';
import {QuestionnaireConfigurationModel} from '../../../models/questionnaireConfiguration.model';
import {AuthenticationService} from '../../../core/services/auth.service';
import {first} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {


  public questionnaire: QuestionnaireModel;
  public user = null;
  public renderer: Renderer2;
  public result: Array<any> = [];
  public currentPage = 1;
  public score = 0;
  public questionnaireRequired = false;
  public hash;

  public loading = false;
  public authenticated = false;
  public loginForm = {
    user: null,
    password: null
  };

  @ViewChild('modalLogin', {static: true}) modalLogin: TemplateRef<any>;


  constructor(
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
    private questionnaireService: QuestionnaireService,
    private rendererFactory: RendererFactory2,
    private authenticationService: AuthenticationService,
    private router: Router,
    private modal: NgbModal
  ) {
    this.questionnaire = new QuestionnaireModel();
    this.questionnaire.configuration = new QuestionnaireConfigurationModel();
    spinnerService.show();
    route.params.subscribe(param => {
      if (param.hash && param.hash.length === 8) {
        this.hash = param.hash;
        this.renderer = rendererFactory.createRenderer(null, null);
        this.user = authenticationService.currentUser();
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  ngOnInit(): void {
    if (this.user && this.user.id) {
      this.getQuestionnaire();
      this.authenticated = true;
    } else {
      this.spinnerService.hide();
      this.authenticated = false;
      this.openModal();
    }
  }

  openModal() {
    this.modal.open(this.modalLogin, {windowClass: 'custom-modal-class', centered: true, keyboard: false});
  }

  getQuestionnaire() {
    setInterval(() => {
      for (let x = 0, page = 1; x < this.questionnaire.configuration.numberOfPages; x++) {
        const li = document.getElementById('page-li-' + page);
        this.renderer.setProperty(li, 'innerHTML', this.buildValues(page));
        page++;
      }
    }, 1000);
    this.questionnaireService.getByHash(this.hash).subscribe(res => {
      if (res.answered) {
        this.showMessage('Parece que você já respondeu esse questionário.', 'info', 'Oops...', '/');
      } else {
        this.questionnaire = res[0];
        this.render();
      }
    });
  }

  render() {
    this.questionnaire.questions = this.questionnaire.questions.sort((a, b) => a.order - b.order);
    const form = document.getElementById('form');
    const title = document.createElement('div');
    this.renderer.setStyle(form, 'width', '100%');
    this.renderer.addClass(title, 'col-md-12');
    this.renderer.addClass(title, 'col-sm-12');
    this.renderer.addClass(title, 'text-center');
    this.renderer.setProperty(title, 'innerHTML', ``);
    this.renderer.appendChild(form, title);
    if (this.questionnaire.configuration.singlePage) {
      this.questionnaire.questions.forEach((questionnaire) => {
        const component = this.buildComponent(questionnaire);
        this.renderer.appendChild(form, component);
      });
      this.buildAnswers();
      this.spinnerService.hide();
    } else {
      const container = document.createElement('div');
      this.renderer.addClass(container, 'col-md-12');
      this.renderer.addClass(container, 'col-sm-12');
      for (let x = 0, page = 1; x < this.questionnaire.configuration.numberOfPages; x++) {
        const containerTitle = document.createElement('div');
        this.renderer.addClass(containerTitle, 'form_title');
        this.renderer.addClass(containerTitle, 'pages-title-content');
        this.renderer.setAttribute(containerTitle, 'id', 'title-' + page);
        this.renderer.setProperty(containerTitle, 'innerHTML', `<h3><strong>${page}ª</strong> Página de ${this.questionnaire.configuration.numberOfPages}</h3><p>Para prosseguir responda o que se pede.</p>`);
        const containerElement = document.createElement('div');
        this.renderer.addClass(containerElement, 'row');
        this.renderer.addClass(containerElement, 'pages-tab-content');
        if (page === 1) {
          this.renderer.setStyle(containerElement, 'display', 'block');
          this.renderer.setStyle(containerTitle, 'display', 'block');
        }
        this.renderer.setAttribute(containerElement, 'id', 'page-' + page);
        this.questionnaire.questions.forEach((questionnaire) => {
          if (questionnaire.numberOfPage === page) {
            const component = this.buildComponent(questionnaire);
            this.renderer.appendChild(containerElement, component);
          }
        });
        this.renderer.appendChild(container, containerTitle);
        this.renderer.appendChild(container, containerElement);
        page++;
      }
      const containerButtons = this.createContainerButtonsWizard();
      this.renderer.appendChild(container, containerButtons);
      this.renderer.appendChild(form, container);
      this.buildAnswers();
      this.spinnerService.hide();
    }
  }

  buildComponent(questionnaire) {
    if (questionnaire.component.name === 'IMC') {
      this.result.push({
        question: 'IMC',
        required: questionnaire.required,
        type: questionnaire.component.type,
        page: questionnaire.numberOfPage
      });
      const container = document.createElement('div');
      this.renderer.addClass(container, 'col-md-12');
      this.renderer.addClass(container, 'col-sm-12');
      this.renderer.addClass(container, 'mt-2');
      const containerElement = document.createElement('div');
      this.renderer.addClass(containerElement, 'row');

      const heightContainer = document.createElement('div');
      this.renderer.addClass(heightContainer, 'col-md-6');
      this.renderer.addClass(heightContainer, 'col-sm-6');
      this.renderer.addClass(heightContainer, 'mt-2');

      const containerInputsHeight = document.createElement('div');
      this.renderer.addClass(containerInputsHeight, 'div-component-height');

      const weightContainer = document.createElement('div');
      this.renderer.addClass(weightContainer, 'col-md-6');
      this.renderer.addClass(weightContainer, 'col-sm-6');
      this.renderer.addClass(weightContainer, 'mt-2');
      // LABEL
      const weightLabel = document.createElement('label');
      weightLabel.innerText = 'IMC';
      if (questionnaire.help) {
        const tooltip = document.createElement('div');
        this.renderer.addClass(tooltip, 'tooltip-help');
        tooltip.innerHTML = `<strong>?</strong><span class="tooltip-help-text">${questionnaire.help}</span>`;
        this.renderer.appendChild(weightLabel, tooltip);
      }
      this.renderer.appendChild(weightContainer, weightLabel);
      const heightLabel = document.createElement('label');
      heightLabel.innerText = ' ';
      this.renderer.appendChild(heightContainer, heightLabel);
      // COMPONENT
      const weightElement = document.createElement('input');
      this.renderer.setAttribute(weightElement, 'type', 'number');
      this.renderer.setProperty(weightElement, 'placeholder', 'Peso (Kg)');
      this.renderer.addClass(weightElement, questionnaire.component.class);
      this.renderer.setAttribute(weightElement, 'name', 'imc-weight');
      this.renderer.setAttribute(weightContainer, 'id', 'imc-weight');
      this.renderer.appendChild(weightContainer, weightElement);

      const heightElement = document.createElement('input');
      this.renderer.setAttribute(heightElement, 'type', 'number');
      this.renderer.setProperty(heightElement, 'placeholder', 'M');
      this.renderer.setProperty(heightElement, 'min', '1');
      this.renderer.setProperty(heightElement, 'max', '2');
      this.renderer.addClass(heightElement, questionnaire.component.class);
      this.renderer.setAttribute(heightElement, 'name', 'imc-height-m');
      this.renderer.appendChild(containerInputsHeight, heightElement);

      const heightElement2 = document.createElement('input');
      this.renderer.setAttribute(heightElement2, 'type', 'number');
      this.renderer.setProperty(heightElement2, 'placeholder', 'CM');
      this.renderer.setProperty(heightElement2, 'min', '1');
      this.renderer.addClass(heightElement2, questionnaire.component.class);
      this.renderer.setAttribute(heightElement2, 'name', 'imc-height-cm');
      this.renderer.appendChild(containerInputsHeight, heightElement2);

      this.renderer.appendChild(heightContainer, containerInputsHeight);

      this.renderer.appendChild(containerElement, weightContainer);
      this.renderer.appendChild(containerElement, heightContainer);
      this.renderer.appendChild(container, containerElement);
      return container;
    } else {
      // CONTAINER DO COMPONENT
      const containerElement = document.createElement('div');
      this.renderer.addClass(containerElement, 'col-md-12');
      this.renderer.addClass(containerElement, 'col-sm-12');
      this.renderer.addClass(containerElement, 'mt-2');
      // LABEL COM O NOME ESCOLHIDO PARA O CAMPO
      const label = document.createElement('label');
      label.innerText = questionnaire.question;
      if (questionnaire.help) {
        const tooltip = document.createElement('div');
        this.renderer.addClass(tooltip, 'tooltip-help');
        tooltip.innerHTML = `<strong>?</strong><span class="tooltip-help-text">${questionnaire.help}</span>`;
        this.renderer.appendChild(label, tooltip);
      }
      this.renderer.appendChild(containerElement, label);
      // VALIDA O TIPO PARA SETAR AS CONFIG
      // TEXT ou NUMBER ou TEXTERA ou DATE
      if (questionnaire.component.type === 'text' || questionnaire.component.type === 'number' || questionnaire.component.type === 'textarea' || questionnaire.component.type === 'date') {
        const element = document.createElement(questionnaire.component.html);
        const name = questionnaire.question;
        this.result.push({
          question: name,
          required: questionnaire.required,
          type: questionnaire.component.type,
          page: questionnaire.numberOfPage
        });
        this.renderer.setAttribute(element, 'type', questionnaire.component.type);
        this.renderer.addClass(element, questionnaire.component.class);
        this.renderer.setAttribute(element, 'name', name);
        this.renderer.setAttribute(containerElement, 'id', name);
        this.renderer.appendChild(containerElement, element);
        if (questionnaire.required) {
          const alert = document.createElement('small');
          const message = this.renderer.createText('Campo obrigatorio');
          this.renderer.addClass(alert, 'text-danger');
          this.renderer.appendChild(alert, message);
          this.renderer.setAttribute(alert, 'id', 'small ' + questionnaire.question);
          this.renderer.setStyle(alert, 'display', 'none');
          this.renderer.appendChild(containerElement, alert);
        }
        return containerElement;
        // RADIO ou CHECKBOX
      } else if (questionnaire.component.type === 'radio' || questionnaire.component.type === 'checkbox') {
        const classAdditional = questionnaire.component.type === 'radio' ? 'custom-radio' : 'custom-checkbox';
        const name = questionnaire.question;
        this.result.push({
          question: name,
          required: questionnaire.required,
          type: questionnaire.component.type,
          page: questionnaire.numberOfPage
        });
        for (let i = 0; i < questionnaire.options.length; i++) {
          const option = questionnaire.options[i];
          let text = questionnaire.question.replace(' ', '');
          const id = questionnaire.component.type + text + i;
          // CONTAINER DO INPUT
          const containerComponent = document.createElement('div');
          this.renderer.addClass(containerComponent, 'custom-control');
          this.renderer.addClass(containerComponent, classAdditional);
          // CRIAÇÃO DO COMPONENT
          const element = document.createElement(questionnaire.component.html);
          this.renderer.setAttribute(element, 'type', questionnaire.component.type);
          this.renderer.setAttribute(element, 'name', name);
          this.renderer.setAttribute(element, 'id', id);
          this.renderer.setAttribute(element, 'value', option.value);
          if (i === 0 && questionnaire.component.type === 'radio') {
            const test: any = true;
            this.renderer.setAttribute(element, 'checked', test);
          }
          this.renderer.addClass(element, questionnaire.component.class);
          this.renderer.appendChild(containerComponent, element);
          // LABEL COM O NOME ESCOLHIDO PARA A OPÇÃO
          const label = document.createElement('label');
          label.innerText = option.value;
          this.renderer.setAttribute(label, 'for', id);
          this.renderer.addClass(label, 'custom-control-label');
          this.renderer.appendChild(containerComponent, label);
          this.renderer.appendChild(containerElement, containerComponent);
        }
        if (questionnaire.required) {
          const alert = document.createElement('small');
          const message = this.renderer.createText('Campo obrigatorio');
          this.renderer.addClass(alert, 'text-danger');
          this.renderer.setStyle(alert, 'display', 'none');
          this.renderer.setAttribute(alert, 'id', 'small ' + questionnaire.question);
          this.renderer.appendChild(alert, message);
          this.renderer.appendChild(containerElement, alert);
        }
        return containerElement;
      } else if (questionnaire.component.type === 'select') {
        const element = document.createElement(questionnaire.component.html);
        this.renderer.addClass(element, questionnaire.component.class);
        const name = questionnaire.question;
        this.result.push({
          question: name,
          required: questionnaire.required,
          type: questionnaire.component.type,
          page: questionnaire.numberOfPage
        });
        this.renderer.setAttribute(element, 'name', name);
        questionnaire.options.forEach((option) => {
          const elementOption = document.createElement('option');
          this.renderer.setAttribute(elementOption, 'value', option.value);
          const nameOption = this.renderer.createText(option.value);
          this.renderer.appendChild(elementOption, nameOption);
          this.renderer.appendChild(element, elementOption);
        });
        this.renderer.appendChild(containerElement, element);
        if (questionnaire.required) {
          const alert = document.createElement('small');
          const message = this.renderer.createText('Campo obrigatorio');
          this.renderer.addClass(alert, 'text-danger');
          this.renderer.appendChild(alert, message);
          this.renderer.setAttribute(alert, 'id', 'small ' + questionnaire.question);
          this.renderer.setStyle(alert, 'display', 'none');
          this.renderer.appendChild(containerElement, alert);
        }
        return containerElement;
      }
    }
  }

  createContainerButtonsWizard() {
    const containerButtons = document.createElement('div');
    this.renderer.addClass(containerButtons, 'col-md-12');
    this.renderer.addClass(containerButtons, 'col-sm-12');
    this.renderer.addClass(containerButtons, 'mt-5');
    this.renderer.addClass(containerButtons, 'mb-5');
    this.renderer.addClass(containerButtons, 'div-container-btn-pages');
    this.renderer.addClass(containerButtons, 'text-right');
    const previousButton = document.createElement('button');
    this.renderer.addClass(previousButton, 'btn');
    this.renderer.addClass(previousButton, 'btn-primary');
    this.renderer.listen(previousButton, 'click', () => this.nextOrPreviousPage('previous'));
    const previousText = this.renderer.createText('Voltar');
    this.renderer.appendChild(previousButton, previousText);
    this.renderer.appendChild(containerButtons, previousButton);
    this.renderer.addClass(previousButton, 'ml-2');
    this.renderer.addClass(previousButton, 'mr-2');
    const nextButton = document.createElement('button');
    this.renderer.addClass(nextButton, 'btn');
    this.renderer.addClass(nextButton, 'btn-primary');
    this.renderer.setAttribute(nextButton, 'id', 'btn-next');
    this.renderer.listen(nextButton, 'click', () => this.nextOrPreviousPage('next'));
    const nextText = this.renderer.createText('Próximo');
    this.renderer.appendChild(nextButton, nextText);
    this.renderer.appendChild(containerButtons, nextButton);
    return containerButtons;
  }

  nextOrPreviousPage(action) {
    const page = action === 'next' ? ((this.currentPage + 1) > this.questionnaire.configuration.numberOfPages) ? this.currentPage : this.currentPage + 1 : this.currentPage > 1 ? this.currentPage - 1 : 1;
    const questions = this.result.filter((row) => row.page <= page);
    const elements = document.getElementById('form')['elements'];
    if (!this.validAnswers(questions, elements)) {
      const tabContents = document.getElementsByClassName('pages-tab-content');
      const titleContents = document.getElementsByClassName('pages-title-content');
      for (let i = 0; i < tabContents.length; i++) {
        tabContents[i]['style'].display = 'none';
      }
      for (let i = 0; i < titleContents.length; i++) {
        titleContents[i]['style'].display = 'none';
      }
      document.getElementById('page-' + page).style.display = 'initial';
      document.getElementById('title-' + page).style.display = 'block';
      document.getElementById('page-li-' + page).style.display = 'initial';
      this.currentPage = page;
      document.getElementById('btn-next').style.display = this.questionnaire.configuration.numberOfPages === this.currentPage ? 'none' : 'initial';
    }
  }

  buildAnswers() {
    if (this.questionnaire.configuration.singlePage) {
      let html = '';
      this.questionnaire.questions.forEach((row) => {
        const elements = document.getElementById('form')['elements'];
        if (row.component.name !== 'IMC') {
          if (row.component.type === 'checkbox') {
            html += `<strong>${row.question}  :</strong><br/>`;
            for (let i = 0; i < elements[row.question].length; i++) {
              const option = elements[row.question][i];
              if (option.checked) {
                html += `${option.defaultValue}<br/>`;
              }
            }
            html += `<br/><br/>`;
          } else {
            html += `<strong>${row.question} :</strong> ${elements[row.question].value}<br/><br/>`;
          }
        } else {
          html += `<strong>IMC :</strong>Peso (KG): ${elements['imc-weight'].value} - Altura: ${elements['imc-height-m'].value},${elements['imc-height-cm'].value}<br/><br/>`;
        }
      });
      return html;
    } else {
      const container = document.getElementById('container-answers');
      this.renderer.setProperty(container, 'innerHTML', '');
      for (let x = 0, page = 1; x < this.questionnaire.configuration.numberOfPages; x++) {
        const li = document.createElement('li');
        this.renderer.setAttribute(li, 'id', 'page-li-' + page);
        this.renderer.addClass(li, 'mb-2');
        if (page > this.currentPage) {
          this.renderer.addClass(li, 'pages-li');
        }
        this.renderer.setProperty(li, 'innerHTML', this.buildValues(page));
        this.renderer.appendChild(container, li);
        page++;
      }
    }
  }

  buildValues(page) {
    let html = `<span class="mt-2 mb-2"><strong>${page} PÁGINA</strong><br/></span>`;
    this.questionnaire.questions.forEach((row) => {
      if (page === row.numberOfPage) {
        const elements = document.getElementById('form')['elements'];
        if (row.component.name !== 'IMC') {
          if (row.component.type === 'checkbox') {
            html += `<span class="ml-3"><strong>${row.question}  :</strong>`;
            for (let i = 0; i < elements[row.question].length; i++) {
              const option = elements[row.question][i];
              if (option.checked) {
                html += `${option.defaultValue}<br/>`;
              }
            }
            html += `<br/></span>`;
          } else {
            html += `<span class="ml-3"><strong>${row.question}  :</strong> ${elements[row.question].value} <br/></span>`;
          }
        } else {
          html += `<strong>IMC :</strong>Peso (KG): ${(elements['imc-weight'].value ? elements['imc-weight'].value : 0)} - Altura: ${elements['imc-height-m'].value},${elements['imc-height-cm'].value}<br/><br/>`;
        }
      }
    });
    html += `<hr>`;
    return html;
  }

  save() {
    this.spinnerService.show();
    this.score = 0;
    const elements = document.getElementById('form')['elements'];
    if (!this.validAnswers(this.result, elements)) {
      this.questionnaire.questions.forEach((row) => {
        if (row.options && row.options.length) {
          row.options.forEach((option) => {
            if (row.component.type === 'checkbox') {
              const index = this.result.findIndex(a => a.question === row.question);
              this.score += this.result[index].answer.findIndex(a => a === option.value) >= 0 ? option.questionValue : 0;
            } else {
              this.score += this.result.findIndex(a => a.answer === option.value) >= 0 ? option.questionValue : 0;
            }
          });
        }
      });
      const data: any = {
        questionnaire: this.questionnaire.id,
        user: this.user,
        company: this.user.company,
        answers: this.result,
        score: this.score
      };
      this.spinnerService.hide();
      this.questionnaireService.saveAnswer(data).subscribe(() => {
        this.showMessageSave(null);
      }, error => this.showMessageSave(error));
    } else {
      this.spinnerService.hide();
    }
  }

  validAnswers(questions, elements) {
    let invalid = false;
    questions.forEach((row) => {
      if (row.type === 'checkbox') {
        row.answer = [];
        let haveValue = false;
        for (let i = 0; i < elements[row.question].length; i++) {
          const option = elements[row.question][i];
          if (option.checked) {
            haveValue = true;
            row.answer.push(option.defaultValue);
          }
        }
        if (!row.required || row.required && haveValue) {
          this.showAlert(row.question, false);
        } else {
          invalid = true;
          this.showAlert(row.question, true);
        }
      } else if (row.question === 'IMC') {
        if (row.required && !elements['imc-weight'].value || !elements['imc-height-m'].value || !elements['imc-height-cm'].value) {
          invalid = true;
          this.showAlert(row.question, true);
        } else {
          this.showAlert(row.question, false);
          const weight = elements['imc-weight'].value ? Number(elements['imc-weight'].value) : null;
          const height = elements['imc-height-m'].value && elements['imc-height-cm'].value ? parseFloat(elements['imc-height-m'].value + '.' + elements['imc-height-cm'].value) : null;
          const imc = weight && height ? (weight / (height * height)) : 0;
          row.answer = {
            weight: weight,
            height: height,
            imc: imc.toFixed(2)
          };
        }
      } else if (!row.required || row.required && elements[row.question].value) {
        this.showAlert(row.question, false);
        row.answer = elements[row.question] && elements[row.question].value ? elements[row.question].value : null;
      } else {
        invalid = true;
        this.showAlert(row.question, true);
      }
    });
    return invalid;
  }

  showMessageSave(errorMessage) {
    this.spinnerService.hide();
    Swal.fire({
      icon: errorMessage ? 'error' : 'success',
      title: errorMessage ? 'Oops...' : 'Sucesso!',
      text: errorMessage ? errorMessage['error'] ? errorMessage['error']['message'] : errorMessage : 'Resposta salva com sucesso!',
    }).then(() => {
      if (!errorMessage) {
        this.router.navigate(['/']);
      }
    });
  }

  showMessage(message, type, title, navigate) {
    this.spinnerService.hide();
    Swal.fire({
      title: title,
      text: message,
      icon: type
    }).then(() => {
      this.router.navigate([navigate]);
    });
  }

  showAlert(question, show) {
    const alert = document.getElementById(('small ' + question));
    if (alert) {
      alert.style.display = show ? 'block' : 'none';
    }
  }

  login() {
    if (!this.loginForm.password || !this.loginForm.user) {
      return;
    } else {
      this.loading = true;
      this.authenticationService.login(this.loginForm.user, this.loginForm.password).pipe(first()).subscribe(data => {
        this.loading = false;
        this.authenticated = true;
        this.modal.dismissAll();
        this.spinnerService.show();
        this.user = this.authenticationService.currentUser();
        this.getQuestionnaire();
      }, res => {
        this.loading = false;
      });
    }
  }
}
