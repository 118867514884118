import { Injectable } from "@angular/core";

declare var $: any;

@Injectable()
export class ToastServiceModule {
  constructor() { }

  showNotification(type: string, message: string, from: string = 'top', align: string = 'right') {
    $.notify(
      {
        icon: "ti-info",
        message: message
      },
      {
        type: type,
        timer: 500,
        delay: 2500,
        placement: {
          from: from,
          align: align
        }
      }
    );
  }
}
