import {QuestionModel} from "./question.model";
import {User} from "./user.model";
import {QuestionnaireConfigurationModel} from "./questionnaireConfiguration.model";
import {Company} from './company.model';

export class QuestionnaireModel {

  id: number;
  user!: User;
  title!: string;
  active: boolean;
  questions!: Array<QuestionModel>;
  configuration!: QuestionnaireConfigurationModel;
  company: Company;

  constructor() {
  }

}
