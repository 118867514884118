import {QuestionnaireModel} from "./questionnaire.model";

export class QuestionnaireConfigurationModel {

  id: number;
  company: any;
  type!: number;
  specialization: any;
  program: any;
  singlePage!: boolean;
  actions!: Array<any>;
  answerSingle!: boolean;
  numberOfPages!: number;
  maximumScore!: number;
  questionnaire: QuestionnaireModel;

  constructor() {
  }
}
