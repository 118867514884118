import {Component, OnInit, AfterViewInit, Input, OnChanges, ViewChild, ElementRef} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';

import MetisMenu from 'metismenujs/dist/metismenujs';

import {activateMenuItems, resetMenuItems} from './utils';
import {MENU} from './menu';
import {MenuItem} from './menu.model';
import {AuthenticationService} from '../../../core/services/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit, OnChanges {

  public menu: any;
  public menuItems = [];
  public user;

  @Input() isCondensed: boolean;
  @Input() mode: string;
  @ViewChild('sideMenu', {static: false}) sideMenu: ElementRef;

  constructor(router: Router, private authService: AuthenticationService) {
    this.user = authService.currentUser();
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
      }
    });
  }

  ngOnInit() {
    this.initialize();
  }

  ngAfterViewInit() {
    this._initMenu();
  }

  ngOnChanges() {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      setTimeout(() => this._initMenu());
    } else if (this.menu) {
      this.menu.dispose();
    }
  }

  _initMenu() {
    if (this.mode === 'horizontal') {
      const menuRef = new MetisMenu(this.sideMenu.nativeElement).on('shown.metisMenu', (event) => {
        window.addEventListener('click', function menuClick(e) {
          if (!event.target.contains(e.target)) {
            menuRef.hide(event.detail.shownElement);
            window.removeEventListener('click', menuClick);
          }
        });
      });
    } else {
      this.menu = new MetisMenu(this.sideMenu.nativeElement);
    }
    this._activateMenuDropdown();
  }

  _activateMenuDropdown() {
    const activeClass = this.mode === 'horizontal' ? 'active' : 'mm-active';
    const dropdownActiveClass = this.mode === 'horizontal' ? 'active' : 'mm-show';
    resetMenuItems(activeClass, dropdownActiveClass);
    if (this.mode === 'horizontal') {
      resetMenuItems('mm-active', 'mm-show');
    }
    activateMenuItems(activeClass, dropdownActiveClass);
  }

  initialize(): void {
    const permission = this.getPermission(this.user.userTagType);
    MENU.forEach((element) => {
      if (permission >= element.permissionRequired) {
        this.menuItems.push(element);
      }
    });
  }

  private getPermission(tag): number {
    let permission: number;
    switch (tag) {
      case 'user_admin':
        permission = 3;
        break;
      case 'user_emp':
        permission = 2;
        break;
      case 'user_emp_admin':
        permission = 2;
        break;
      case 'user_emp_default':
        permission = 1;
        break;
    }
    return permission;
  }

  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  hideMenu() {
    document.body.classList.remove('sidebar-enable');
  }
}
