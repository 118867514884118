import {Observable} from 'rxjs/Observable';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {User} from '../models/user.model';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {environment} from "../../environments/environment";

@Injectable()
export class UsersServiceModule {

  constructor(private http: HttpClient) {
  }

  get(params?: string): Observable<any> {
    return this.http.get<any>(`${environment.server}/api/users`);
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(`${environment.server}/api/users/${id}`);
  }

  getByName(name: string): Observable<any> {
    return this.http.get<any>(`${environment.server}/api/users/getByName/${name}`);
  }

  save(user: User): Observable<any> {
    let method = user.id ? 'put' : 'post';
    return this.http[method]<any>(`${environment.server}/api/users`, user);
  }

  savePassword(user: User): Observable<any> {
    return this.http.put<any>(`${environment.server}/public/newPassword`, user);
  }

  delete(user: User): Observable<any> {
    return this.http.delete<any>(`${environment.server}/api/users/${user.id}`);
  }

  getUserByRedefinePassword(token: any): Observable<any> {
    return this.http.get<any>(`${environment.server}/public/redefinePassword/` + token);
  }

  decodeToken(token: any): Observable<any> {
    return this.http.get<any>(`${environment.server}/public/decodeToken/` + token);
  }

  sendEmailForRedefinePassword(email: any): Observable<any> {
    return this.http.put<any>(`${environment.server}/public/sendEmailForRedefinePassword`, {email: email});
  }

  avatar(user: User): Observable<any> {
    let formData = new FormData();
    for (const key in user) {
      formData.append(key, user[key]);
    }
    return this.http.post(`${environment.server}/api/users/${user.id}/image`, formData);
  }
}
