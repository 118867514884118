import {Injectable} from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders
} from "@angular/common/http";
import 'rxjs/add/operator/do';
import {Observable} from "rxjs";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("token");
    if (token) {
      const secureReq = req.clone({
        headers: new HttpHeaders({
          "Authorization": `Bearer ${token}`
        })
      });
      return next.handle(secureReq).do((event: any) => {
      }, error => {
        console.log(error);
      });
    }
    return next.handle(req);
  }
}
