import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent} from "./login.component";
import {ReactiveFormsModule} from "@angular/forms";
import {UIModule} from "../../../shared/ui/ui.module";
import {NgbAlertModule} from "@ng-bootstrap/ng-bootstrap";
import {ToastServiceModule} from "../../../services/toast.service";
import {AuthenticateServiceModule} from "../../../services/authenticate.service";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbAlertModule,
    UIModule,
    RouterModule
  ],
  providers: [
    ToastServiceModule,
    AuthenticateServiceModule
  ]
})
export class LoginModule { }
