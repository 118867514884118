import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import {ToastServiceModule} from './../../services/toast.service';
import {AuthenticationService} from '../services/auth.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private toastServiceModule: ToastServiceModule,
    private authenticationService: AuthenticationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = localStorage.getItem('token');
    const user = this.authenticationService.currentUser();
    if (token && user) {
      return true;
    } else {
      localStorage.clear();
      this.router.navigate(['/login']);
      this.toastServiceModule.showNotification('warning', 'Ops, você precisa fazer login!');
      return false;
    }
  }
}
