import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable()
export class AuthenticateServiceModule {

  redirectUrl: string;
  isVideoConference = false;

  constructor(
    private http: HttpClient) { }


  signInVideoConference(user: User): Observable<any> {
    return this.http.post<any>(`${environment.server}/signinvideoconference`, user);
  }

  signIn(user: User): Observable<any> {
    return this.http.post<any>(`${environment.server}/auth/signin`, user);
  }

  signUp(user: User): Observable<any> {
    return this.http.post<any>(`${environment.server}/auth/signup`, user);
  }

  validate(token): Observable<any> {
    return this.http.get(`${environment.server}/auth/validate/` + token);
  }

  resetPassword(email: string): Observable<any> {
    let body = new FormData();
    body.append('email', email);
    return this.http.post(`${environment.server}/password/email`, body);
  }

  facebookLogin(user: User): Observable<any> {
    return this.http.post(`${environment.server}/fbsignin`, user);
  }
}
