import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './account/auth/login/login.component';

import {AuthGuard} from './core/guards/auth.guard';
import {LayoutComponent} from './layouts/layout.component';
import {QuestionnaireComponent} from './pages/public/questionnaire/questionnaire.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'formulario/:hash',
    component: QuestionnaireComponent
  },
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () => import('./pages/private/private.module').then(m => m.PrivateModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
