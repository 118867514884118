import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

import {CookieService} from '../services/cookie.service';
import {environment} from 'src/environments/environment';
import {User} from '../../models/user.model';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  user: User;
  href: string = environment.server;

  constructor(private http: HttpClient, private cookieService: CookieService) {
  }

  public currentUser(): User {
    if (!this.user) {
      this.user = JSON.parse(this.cookieService.getCookie('currentUser'));
    }
    return this.user;
  }

  public login(email: string, password: string) {
    return this.http.post<any>(`${this.href}/auth/signin`, {email, password}).pipe(map(res => {
      if (res.user && res.token) {
        this.user = res.user;
        this.cookieService.setCookie('currentUser', JSON.stringify(res.user), 1);
        localStorage.setItem('token', res.token);
      }
      return res.user;
    }));
  }

  public logout() {
    this.cookieService.deleteCookie('currentUser');
    this.cookieService.deleteCookie('tagUser');
    localStorage.clear();
    this.user = null;
  }
}

