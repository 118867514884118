import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoginModule } from "./account/auth/login/login.module";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppHttpInterceptor } from "./app.http-interceptor";
import { LayoutsModule } from './layouts/layouts.module';
import { ToastServiceModule } from "./services/toast.service";
import { UsersServiceModule } from "./services/users.service";
import flatpickr from "flatpickr";
import {Portuguese} from "flatpickr/dist/l10n/pt";
import { AuthGuard } from './core/guards/auth.guard';
import {QuestionnaireModule} from './pages/public/questionnaire/questionnaire.module';

export function flatpickrFactory() {
  flatpickr.localize(Portuguese);
  return flatpickr;
}

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AppHttpInterceptor,
    multi: true
  }
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutsModule,
    AppRoutingModule,
    LoginModule,
    MatSlideToggleModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    QuestionnaireModule
  ],
  providers: [
    httpInterceptorProviders,
    UsersServiceModule,
    ToastServiceModule,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
