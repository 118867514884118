import {MenuItem} from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Navigation',
    isTitle: true,
    permissionRequired: 1
  },
  {
    label: 'Dashboard',
    icon: 'home',
    link: '/',
    permissionRequired: 1
  },
  {
    label: 'Resultados',
    icon: 'clipboard',
    link: '/answers',
    permissionRequired: 2
  },
  {
    label: 'Modulos',
    isTitle: true,
    permissionRequired: 1
  },
  {
    label: 'Empresas',
    name: 'company',
    icon: 'briefcase',
    link: '/companies',
    permissionRequired: 3
  },
  {
    label: 'Usuários',
    name: 'user',
    icon: 'users',
    link: '/users',
    permissionRequired: 2
  },
  {
    label: 'Formulários',
    name: 'questionnaire',
    icon: 'inbox',
    link: '/dynamic-forms',
    permissionRequired: 2
  }
];
