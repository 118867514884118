import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {QuestionnaireModel} from '../models/questionnaire.model';

@Injectable()
export class QuestionnaireService {

  constructor(private http: HttpClient) {
  }

  get(params?: string): Observable<any> {
    return this.http.get<any>(`${environment.server}/api/questionnaire/${params ? ('?' + params) : ''}`);
  }

  getByHash(hash?: string): Observable<any> {
    return this.http.get<any>(`${environment.server}/api/questionnaire/hash/${hash}`);
  }

  getResult(params?: string): Observable<any> {
    return this.http.get<any>(`${environment.server}/api/questionnaire-result/${params ? ('?' + params) : ''}`);
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(`${environment.server}/api/questionnaire/${id}`);
  }

  save(data: QuestionnaireModel): Observable<any> {
    let method = data.id ? 'put' : 'post';
    return this.http[method]<any>(`${environment.server}/api/questionnaire/`, data);
  }

  saveAnswer(data: QuestionnaireModel): Observable<any> {
    return this.http.post<any>(`${environment.server}/api/questionnaire/save/answer`, data);
  }

  getAnswer(company): Observable<any> {
    return this.http.get<any>(`${environment.server}/api/questionnaire/answers/${company}`);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.server}/api/questionnaire/${id}`);
  }

  getFormComponents(): Observable<any> {
    return this.http.get<any>(`${environment.server}/api/form-component`);
  }
}
