import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QuestionnaireComponent} from './questionnaire.component';
import {QuestionnaireService} from '../../../services/questionnaire.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthenticateServiceModule} from '../../../services/authenticate.service';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [QuestionnaireComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule
  ],
  providers: [
    QuestionnaireService,
    NgxSpinnerService,
    AuthenticateServiceModule,
  ]
})
export class QuestionnaireModule {
}
